import {Injectable} from '@angular/core';
import {ShebaHttpService} from '../../../../../modules/sheba-http/sheba-http.service';
import {StorageService} from '../../../../../services/storage.service';
import {ActivatedRouteSnapshot} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {PopAlertService} from '../../../../../modules/pop-alert/pop-alert.service';

@Injectable({
    providedIn: 'root'
})
export class AnnouncementDetailsService {

    baseUrl: string;

    constructor(private http: ShebaHttpService,
                private pop: PopAlertService,
                private storage: StorageService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        this.baseUrl = 'v3/businesses/' + this.storage.user.business_id;
        const requestId = route.params.announcement_id;
        return this.getAnnouncementDetails(requestId);
    }
    getAnnouncementDetails(requestID: string) {
        const url = this.baseUrl + '/announcements/' + requestID;
        return this.getApiCall(url);
    }

    getAnnouncementNotificationCount(requestID) {
        const url = this.baseUrl + '/announcements/' + requestID + '/notification';
        return this.getApiCall(url);
    }
    private getApiCall(url: string) {
        return this.http.get(url).pipe(catchError(err => {
            this.pop.open(err.message);
            return of([]);
        }));
    }
}
