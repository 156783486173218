import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EmployeeVisitsSharedService {

    private employeeVisitsNavigationButtonClickSource = new Subject();
    private employeeVisitsDownloadBtnSource = new Subject<boolean>(); // Navigation Download Pay Data Button Visibility

    currentEmployeeVisitsNavigationButtonClick = this.employeeVisitsNavigationButtonClickSource.asObservable();
    currentEmployeeVisitsDownloadBtnStatus = this.employeeVisitsDownloadBtnSource.asObservable();

    employeeVisitsNavigationButtonClick() {
        this.employeeVisitsNavigationButtonClickSource.next();
    }

    updateEmployeeVisitsDownloadBtnVisibility(show: boolean) {
        this.employeeVisitsDownloadBtnSource.next(show);
    }

}
