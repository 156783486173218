import { Injectable } from '@angular/core';
import {catchError, switchMap} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import {Resolve} from '@angular/router';
import {ShebaHttpService} from '../../../../modules/sheba-http/sheba-http.service';
import {OwnVisitListFilterParams} from '../employee-visits-types/own-visit-types';
import {StorageService} from '../../../../services/storage.service';
import {EmployeeVisitsListOwnModel} from '../employee-visits-list-own/employee-visits-list-own.model';

@Injectable({
    providedIn: 'root'
})

export class EmployeeVisitsListOwnService implements Resolve<any> {

    employeeVisitOwnModel = new EmployeeVisitsListOwnModel();

    constructor(private http: ShebaHttpService,
                private storage: StorageService) { }

    resolve(): Observable<any> {
        const params = this.employeeVisitOwnModel.constructedFilterParams();
        return this.getOwnVisitList(params);
    }

    getOwnVisitList(params: OwnVisitListFilterParams) {
        const url = `v2/businesses/${this.storage.user.business_id}/employee-visit/my-visits`;
        return this.apiCall(url, params);
    }

    private apiCall(url, params?) {
        return this.http.get(url, { params, responseType: 'json' }).pipe(
            switchMap(response => response.code === 200 ? of(response) : throwError(response)),
            catchError(() => of({ error: true }))
        );
    }
}
