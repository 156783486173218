import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router/src/interfaces';
import {Observable, of, Subject, throwError} from 'rxjs';
import { StorageService } from '../../../../../../services/storage.service';
import { ShebaHttpService } from '../../../../../../modules/sheba-http/sheba-http.service';
import { catchError, switchMap } from 'rxjs/operators';
import { PopAlertService } from '../../../../../../modules/pop-alert/pop-alert.service';
import { PayrollSettings } from '../../../../../../types/payroll-settings';

@Injectable({
    providedIn: 'root'
})

export class PayrollSettingsService implements Resolve<PayrollSettings> {

    public stepperScoreSource = new Subject();

    baseUrl: string;

    constructor(private pop: PopAlertService,
                private http: ShebaHttpService,
                private storage: StorageService) { }

    resolve(): Observable<PayrollSettings> {
        this.baseUrl = 'v2/businesses/' + this.storage.user.business_id;
        return this.getPayrollSettings();
    }

    getPayrollSettings() {
        const url = `v2/businesses/${this.storage.user.business_id}/payroll/settings`;
        return this.http.get(url).pipe(
            switchMap(response => response.code === 200 ? of(response.payroll_setting) : throwError(response)),
            catchError(error => {
                this.pop.open(error.message);
                return of([]);
            })
        );
    }

    updateSalaryBreakdown(setting_id, data) {
        const url = this.baseUrl + '/payroll/gross-component/' + setting_id;
        return this.http.post(url, data);
    }

    updatePaySchedule(setting_id, data) {
        const url = this.baseUrl + '/payroll/pay-schedule/' + setting_id;
        return this.http.post(url, data);
    }

    retrievePayrollSettings() {
        const url = `v2/businesses/${this.storage.user.business_id}/payroll/settings`;
        return this.http.get(url);
    }

    updatePayComponent(setting_id, data) {
        const url = this.baseUrl + '/payroll/component/' + setting_id;
        return this.http.post(url, data);
    }

    getDepartments() {
        const url = this.baseUrl + '/departments?is_tagged=1';
        return this.http.get(url);
    }

    getEmployees() {
        const url = this.baseUrl + '/employees?offset=0&limit=400&status=active';
        return this.http.get(url);
    }

    deleteSalaryComponent(setting_id, component_id) {
        const url = this.baseUrl + '/payroll/gross-component/' + setting_id + '/component/' + component_id;
        return this.http.delete(url);
    }

    getPayDayValidationData(params) {
        const url = this.baseUrl + '/payroll/pay-day';
        return this.apiCall(url, params).toPromise();
    }

    getMonthlyPayCycle(params) {
        const url = this.baseUrl + '/payroll/monthly-pay-cycle';
        return this.apiCall(url, params).toPromise();
    }

    private apiCall(url, params?) {
        return this.http.get(url, { params, responseType: 'json' }).pipe(
            switchMap(response => response.code === 200 ? of(response) : throwError(response)),
            catchError(() => of({ error: true }))
        );
    }
}
