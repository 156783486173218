import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

    url: string;

    constructor(
        public route: ActivatedRoute,
        public dialogRef: MatDialogRef<ConfirmationModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            type: string,
            title: string,
            text: string,
            actionText: string,
            link: string,
            code: any
        }
    ) {
        this.url = this.route.snapshot['_routerState'].url;
    }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    downloadFile() {
        window.open(this.data.link, '_blank');
    }
}
