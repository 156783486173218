import {MonthlyAttendanceFilterParams} from './attendance-monthly-list.type';
import * as moment from 'moment';

export const COWORKER_STATUS = [
    { value: 'all', viewValue: 'All Coworker Status' },
    { value: 'active', viewValue: 'Active Coworkers' },
    { value: 'inactive',  viewValue: 'Inactive Coworkers' }
];

export const INITIAL_MONTHLY_ATTENDANCE_FILTER_PARAMS: MonthlyAttendanceFilterParams = {
    offset: 0,
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
    end_date: moment().endOf('month').format('YYYY-MM-DD'),
    department_id: null,
    status: 'active',
    search: null,
    sort_column: null,
    sort_type: null,
};

export const INITIAL_DATE_RANGE = {
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
};

export const ITEMS_PER_PAGE = 10;
