import {INITIAL_EMPLOYEE_VISIT_MY_FILTER_PARAMS} from './employee-visits-list-own.constants';
import {OwnVisitListFilterParams} from '../employee-visits-types/own-visit-types';

export class EmployeeVisitsListOwnModel {
    initialFilterParams = INITIAL_EMPLOYEE_VISIT_MY_FILTER_PARAMS;

    constructor() { }

    constructedFilterParams(): Partial<OwnVisitListFilterParams> {
        const paramsFromStorage = this.getFilterOptionsFromStorage();
        if (paramsFromStorage) {
            return this.constructMyVisitFilterParams(paramsFromStorage);
        }
        this.setFilterParamsInSessionStorage(this.initialFilterParams);
        return this.constructMyVisitFilterParams(this.initialFilterParams);
    }

    setFilterParamsInSessionStorage(filterParams) {
        sessionStorage.setItem('employeeVisitMy', JSON.stringify(filterParams));
    }

    getFilterOptionsFromStorage() {
        return JSON.parse(sessionStorage.getItem('employeeVisitMy'));
    }

    constructMyVisitFilterParams(filterParams: OwnVisitListFilterParams): OwnVisitListFilterParams {
        const offset = filterParams.offset;
        const limit = filterParams.limit;
        const start_date = filterParams.start_date;
        const end_date = filterParams.end_date;
        const status = filterParams.status !== 'all' ? filterParams.status : null;
        const search = filterParams.search ? filterParams.search : null;

        return Object.assign({},
            {offset},
            {limit},
            {start_date},
            {end_date},
            status && {status},
            search && {search}
        );
    }
}
