import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PayReportSharedService {

    private payReportDownloadBtnSource = new Subject<boolean>(); // Navigation Download Pay Report Button Visibility
    private payrollFilterSource = new Subject<any>(); // Download Excel Params
    private payReportDetailsSource = new Subject<any>();
    private payReportMonthFilterSource = new Subject<any>();


    currentPayReportDownloadBtnStatus = this.payReportDownloadBtnSource.asObservable();
    currentPayrollFilter = this.payrollFilterSource.asObservable();
    currentPayReportDetailsFilter = this.payReportDetailsSource.asObservable();
    currentPayReportMonthFilter = this.payReportMonthFilterSource.asObservable();

    constructor() { }

    updatePayReportDownloadBtnVisibility(show: boolean) {
        this.payReportDownloadBtnSource.next(show);
    }

    changePayrollFilter(filterParams, type) {
        this.payrollFilterSource.next({filterParams, type});
    }

    changePayReportDetailsFilter(filterParams) {
        this.payReportDetailsSource.next(filterParams);
    }

    changePayReportMonthFilter(filterParams) {
        this.payReportMonthFilterSource.next(filterParams);
    }
}
