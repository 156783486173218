import {OwnVisitListFilterParams} from '../employee-visits-types/own-visit-types';
import {ITEMS_PER_PAGE} from '../employee-visits-types/employee-visit-constants';
import * as moment from 'moment';

export const INITIAL_EMPLOYEE_VISIT_MY_FILTER_PARAMS: OwnVisitListFilterParams = {
    offset: 0,
    limit: ITEMS_PER_PAGE,
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
    end_date: moment().endOf('month').format('YYYY-MM-DD'),
    status: null,
    search: null
};
