import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeCheckPipe } from './type-check.pipe';

@NgModule({
    declarations: [ TypeCheckPipe ],
    imports: [ CommonModule ],
    exports: [ TypeCheckPipe ]
})
export class TypeCheckModule {
    static forRoot() {
        return {
            ngModule: TypeCheckModule,
            providers: [],
        };
    }
}
