import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'typeCheck'
})

export class TypeCheckPipe implements PipeTransform {

    transform(element: any): string {
        return typeof element;
    }

}
