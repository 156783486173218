import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router/src/interfaces';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, of, Subject, throwError } from 'rxjs';
import { StorageService } from '../../../../../../services/storage.service';
import { ShebaHttpService } from '../../../../../../modules/sheba-http/sheba-http.service';
import { HolidayApiResponse } from '../office-settings-holiday/office-settings-holiday.type';

@Injectable({
    providedIn: 'root'
})

export class OfficeSettingsHolidayService implements Resolve<HolidayApiResponse> {

    private addHolidayBtnDisableSource = new Subject<boolean>();
    private addHolidayBtnClickSource = new Subject();

    currentAddHolidayBtnDisableStatus = this.addHolidayBtnDisableSource.asObservable();
    currentAddHolidayClickStatus = this.addHolidayBtnClickSource.asObservable();

    constructor(private http: ShebaHttpService,
                private storage: StorageService) { }

    resolve(): Observable<HolidayApiResponse> {
        return this.getHolidayList();
    }

    getHolidayList(params?) {
        const holidayListUrl = `v2/businesses/${this.storage.user.business_id}/holidays`;
        return this.apiCall(holidayListUrl, params);
    }

    addHoliday(data) {
        return this.http.post(`v2/businesses/${this.storage.user.business_id}/holidays`, data);
    }

    updateHoliday(holidayId, data) {
        return this.http.post(`v2/businesses/${this.storage.user.business_id}/holidays/${holidayId}`, data);
    }

    deleteHoliday(holidayId: number) {
        return this.http.delete(`v2/businesses/${this.storage.user.business_id}/holidays/${holidayId}`);
    }

    updateAddHolidayBtnDisableStatus(value: boolean) {
        this.addHolidayBtnDisableSource.next(value);
    }

    updateAddHolidayBtnClick() {
        this.addHolidayBtnClickSource.next();
    }

    private apiCall(url, params?) {
        return this.http.get(url, { params, responseType: 'json' }).pipe(
            switchMap(response => response.code === 200 ? of(response) : throwError(response)),
            catchError(() => of({ error: true }))
        );
    }

}
