import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeaveRequestsRoutingModule } from './leave-requests-routing.module';
import { MatDialogModule } from '@angular/material';
import { ConfirmModalModule } from '../../../../modals/confirm-modal/confirm-modal.module';

@NgModule({
    imports: [
        CommonModule,
        LeaveRequestsRoutingModule,
        MatDialogModule,
        ConfirmModalModule,
    ]
})

export class LeaveRequestsModule {}
