const ITEMS_PER_PAGE = 10;

const INITIAL_TOTAL_CALCULATION = {
    employee: 0,
    department: 0,
    transport: 0,
    food: 0,
    other: 0,
    amount: 0
};

const MONTHS = [
    { key: 'january', title: 'January' },
    { key: 'february', title: 'February' },
    { key: 'march', title: 'March' },
    { key: 'april', title: 'April' },
    { key: 'may', title: 'May' },
    { key: 'june', title: 'June' },
    { key: 'july', title: 'July' },
    { key: 'august', title: 'August' },
    { key: 'september', title: 'September' },
    { key: 'october', title: 'October' },
    { key: 'november', title: 'November' },
    { key: 'december', title: 'December' }
];

export { ITEMS_PER_PAGE, INITIAL_TOTAL_CALCULATION, MONTHS };
