import {Injectable} from '@angular/core';
import {catchError, switchMap} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import {Resolve} from '@angular/router';
import {ShebaHttpService} from '../../../../modules/sheba-http/sheba-http.service';
import {StorageService} from '../../../../services/storage.service';
import {TeamVisitListFilterParams} from '../employee-visits-types/team-visit-types';
import {EmployeeVisitsListTeamModel} from '../employee-visits-list-team/employee-visits-list-team.model';

@Injectable({
    providedIn: 'root'
})

export class EmployeeVisitsListTeamService implements Resolve<any> {

    employeeVisitTeamModel = new EmployeeVisitsListTeamModel();

    constructor(private http: ShebaHttpService,
                private storage: StorageService) {
    }

    resolve(): Observable<any> {
        const params = this.employeeVisitTeamModel.constructedFilterParams();
        return this.getTeamVisitList(params);
    }

    getTeamVisitList(params: TeamVisitListFilterParams) {
        const url = `v2/businesses/${this.storage.user.business_id}/employee-visit/team-visits`;
        return this.apiCall(url, params);
    }

    getDepartmentList() {
        const url = `v2/businesses/${this.storage.user.business_id}/departments`;
        return this.apiCall(url);
    }

    private apiCall(url, params?) {
        return this.http.get(url, {params, responseType: 'json'}).pipe(
            switchMap(response => response.code === 200 ? of(response) : throwError(response)),
            catchError(() => of({error: true}))
        );
    }
}
