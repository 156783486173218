import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OfficeSettingsShiftSharedService {

    private createShiftBtnSource = new Subject<boolean>();

    currentCreateShiftBtnStatus = this.createShiftBtnSource.asObservable();

    private updateCreateShiftBtnVisibility(show: boolean) {
        this.createShiftBtnSource.next(show);
    }

    showCreateShiftBtn() {
        this.updateCreateShiftBtnVisibility(true);
    }

    hideCreateShiftBtn() {
        this.updateCreateShiftBtnVisibility(false);
    }
}
