const ITEMS_PER_PAGE = 10;

const STATUSES = [
    {
        value: 'all',
        viewValue: 'All'
    },
    {
        value: 'created',
        viewValue: 'Created'
    },
    {
        value: 'started',
        viewValue: 'Started'
    },
    {
        value: 'reached',
        viewValue: 'Reached'
    },
    {
        value: 'completed',
        viewValue: 'Completed'
    },
    {
        value: 'cancelled',
        viewValue: 'Cancelled'
    }
];

export { ITEMS_PER_PAGE, STATUSES };
