import { Injectable } from '@angular/core';
import {catchError, switchMap} from 'rxjs/operators';
import {of, Subject, throwError} from 'rxjs';
import {ShebaHttpService} from '../../../../../../modules/sheba-http/sheba-http.service';
import {StorageService} from '../../../../../../services/storage.service';
import { Resolve } from '@angular/router';
import * as moment from 'moment';
import {ITEMS_PER_PAGE} from '../../pay-run/pay-run.constants';

@Injectable({
    providedIn: 'root'
})

export class TaxReportListService implements Resolve<any> {

    private taxReportDownloadBtnSource = new Subject<boolean>(); // Navigation Download Tax Report Button Visibility
    private taxReportNavigationButtonClickSource = new Subject(); // Navigation Download Tax Report Button

    currentTaxReportDownloadBtnStatus = this.taxReportDownloadBtnSource.asObservable();
    currentTaxReportNavigationButtonClick = this.taxReportNavigationButtonClickSource.asObservable();


    constructor(private storage: StorageService,
                private http: ShebaHttpService) { }

    resolve() {
        return this.taxReportResponse();
    }

    async taxReportResponse() {
        const pendingMonth = await this.getPendingMonths();

        if (pendingMonth.error || !pendingMonth.last_tax_report_generated_at) {
            return pendingMonth;
        }

        const { last_tax_report_generated_at: { date } } = pendingMonth;

        const month = moment(date, 'YYYY-MM-DD HH:mm:ss').format('M');
        const year = moment(date, 'YYYY-MM-DD HH:mm:ss').format('Y');

        const params = { offset: 0,  limit: 10, month, year };
        const taxReportList = await this.getTaxReportList(params);
        const { error } = taxReportList;

        if (error) { return taxReportList; }

        return { date,  taxReportList };

        // const params = { offset: 0,  limit: 10, month, year };
        // return await this.getTaxReportList(params);
    }

    getPendingMonths() {
        const pendingMonthsUrl = `v2/businesses/${this.storage.user.business_id}/pay-run/pending-months`;
        return this.apiCall(pendingMonthsUrl).toPromise();
    }

    getTaxReportList(params?) {
        const taxReportUrl = `v2/businesses/${this.storage.user.business_id}/payroll/tax-report`;
        return this.apiCall(taxReportUrl, params).toPromise();
        // return of(require('src/assets/json/tax-report.json'));
    }

    getDepartmentList() {
        const url = `v2/businesses/${this.storage.user.business_id}/departments`;
        return this.apiCall(url);
    }

    updateTaxReportDownloadBtnVisibility(show: boolean) {
        this.taxReportDownloadBtnSource.next(show);
    }

    postToTaxReportDownloadBanner() {
        const url = `v2/businesses/${this.storage.user.business_id}/payroll/show-tax-report-download-banner`;
        const data = { show_banner: 0 };
        return this.http.post(url, data);
    }

    taxReportNavigationButtonClick() {
        this.taxReportNavigationButtonClickSource.next();
    }

    private apiCall(url, params?) {
        return this.http.get(url, { params, responseType: 'json' }).pipe(
            switchMap(response => response.code === 200 ? of(response) : throwError(response)),
            catchError(() => of({ error: true }))
        );
    }
}
