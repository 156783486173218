import {INITIAL_EMPLOYEE_VISIT_TEAM_FILTER_PARAMS} from './employee-visits-list-team.constants';
import {TeamVisitListFilterParams} from '../employee-visits-types/team-visit-types';

export class EmployeeVisitsListTeamModel {
    initialFilterParams = INITIAL_EMPLOYEE_VISIT_TEAM_FILTER_PARAMS;

    constructor() { }

    constructedFilterParams(): Partial<TeamVisitListFilterParams> {
        const paramsFromStorage = this.getFilterOptionsFromStorage();
        if (paramsFromStorage) {
            return this.constructTeamVisitFilterParams(paramsFromStorage);
        }
        this.setFilterParamsInSessionStorage(this.initialFilterParams);
        return this.constructTeamVisitFilterParams(this.initialFilterParams);
    }

    setFilterParamsInSessionStorage(filterParams) {
        sessionStorage.setItem('employeeVisitTeam', JSON.stringify(filterParams));
    }

    getFilterOptionsFromStorage() {
        return JSON.parse(sessionStorage.getItem('employeeVisitTeam'));
    }

    constructTeamVisitFilterParams(params: TeamVisitListFilterParams): TeamVisitListFilterParams {
        const offset = params.offset;
        const limit = params.limit;
        const start_date = params.start_date;
        const end_date = params.end_date;
        const department_id = params.department !== 'all' ? params.department : null;
        const status = params.status !== 'all' ? params.status : null;
        const search = params.search ? params.search : null;

        return Object.assign({},
            {offset},
            {limit},
            {start_date},
            {end_date},
            department_id && {department_id},
            status && {status},
            search && {search}
        );
    }
}
